import React from 'react';
import './AnnouncementBar.css';

// Any News update or notifications can be displayed at the top by
// activating this component.
// For example, a new version announcement can be displayed via this section
const AnnouncementBar: React.FC = () => {
    return (
        <div className="ms-landing-home-notification-bar">
        </div>
    );
};

export default AnnouncementBar;
