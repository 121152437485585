import React from 'react';
import './MSLogo.css';
import TextField from '../components/TextField';
import useScreenSize from '../hooks/useScreenSize';
import {
    LOGO_TEXT_SIZE_MOBILE,
    LOGO_TEXT_SIZE_DESKTOP,
    LOGO_ICON_SIZE_DESKTOP,
    LOGO_ICON_SIZE_MOBILE
} from '../config/constants';

const MSIcon: React.FC<{ size: number }> = ({ size }) => (
    <div className="ms-logo-icon" style={{ width: size, height: size }}>
        <img src="/mixstream-icon-512px.png" alt="Mixstream Logo" style={{ width: '100%', height: '100%' }} />
    </div>
);

const MSLogo: React.FC = () => {
    const isMobile = useScreenSize();
    return (
        <div className="logo">
            <MSIcon size={isMobile ? LOGO_ICON_SIZE_MOBILE : LOGO_ICON_SIZE_DESKTOP} />
            <div className="ms-logo-text">
                <div className="ms-logo-text-main">
                    <TextField 
                        text='mix' 
                        size={
                            isMobile ? LOGO_TEXT_SIZE_MOBILE : LOGO_TEXT_SIZE_DESKTOP
                        } 
                        weight='bold'
                        style={{ color: '#e9002f' }}
                    />
                </div>
                <div className="ms-logo-text-sub">
                    <TextField
                        text='stream'
                        size={
                            isMobile ? LOGO_TEXT_SIZE_MOBILE : LOGO_TEXT_SIZE_DESKTOP
                        } 
                        weight='bold'
                    />
                </div>
            </div>
        </div>
    );
};

export default MSLogo;