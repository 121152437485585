import React from 'react';
import './StayUpdatedSection.css';
import MSButton from '../../core-components/MSButton';
import TextField from '../../components/TextField';
import useScreenSize from '../../hooks/useScreenSize';
import { STAY_UPDATED_DESCRIPTION_FONT_SIZE_DESKTOP, STAY_UPDATED_DESCRIPTION_FONT_SIZE_MOBILE, STAY_UPDATED_TITLE_FONT_SIZE_DESKTOP, STAY_UPDATED_TITLE_FONT_SIZE_MOBILE } from '../../config/constants';

const StayUpdatedSection: React.FC = () => {
    const isMobile = useScreenSize();

    return (
        <div className="stay-updated-section">
            <div className="stay-updated-header">
                <TextField 
                    text="Join the Movement for Artistic Freedom" 
                    size={isMobile ? STAY_UPDATED_TITLE_FONT_SIZE_MOBILE : STAY_UPDATED_TITLE_FONT_SIZE_DESKTOP} 
                    weight="bold"
                />
                <TextField
                    text="Subscribe to receive the latest updates and news about Mixstream"
                    size={isMobile ? STAY_UPDATED_DESCRIPTION_FONT_SIZE_MOBILE : STAY_UPDATED_DESCRIPTION_FONT_SIZE_DESKTOP} 
                    style={{ color: '#909090', marginBottom: '40px' }}
                />
            </div>
            <div className="stay-updated-form">
                <input type="email" className="email-input" placeholder="Enter your email" />
                <MSButton text="Subscribe" variant='contained' className='subscribe-button'/>
            </div>
        </div>
    );
};

export default StayUpdatedSection;
