import React from 'react';
import './MultiSelectDropdown.css';

type MultiSelectDropdownProps = {
    options: string[];
    selectedOptions: string[];
    setSelectedOptions: (options: string[]) => void;
    showSelection?: boolean;
    className?: string;
    style?: React.CSSProperties;
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
    options,
    selectedOptions,
    setSelectedOptions,
    showSelection = true,
    className,
    style
}) => {

    const handleOptionClick = (option: string) => {
        let updatedSelectedOptions;

        if (selectedOptions.includes(option)) {
            updatedSelectedOptions = selectedOptions.filter(selectedOption => selectedOption !== option);
        } else {
            updatedSelectedOptions = [...selectedOptions, option];
        }

        setSelectedOptions(updatedSelectedOptions);
    };

    const handleRemoveOption = (option: string) => {
        const updatedSelectedOptions = selectedOptions.filter(selectedOption => selectedOption !== option);
        setSelectedOptions(updatedSelectedOptions);
    };

    const sortedOptions = [...selectedOptions, ...options.filter(option => !selectedOptions.includes(option))];

    return (
        <div className={`multi-select-dropdown ${className}`} style={style}>
            {showSelection && (
                <div className="selected-options">
                    {selectedOptions.map(option => (
                        <span key={option} className="selected-option">
                            {option}
                            <span className="remove-option" onClick={() => handleRemoveOption(option)}>x</span>
                        </span>
                    ))}
                </div>
            )}
            <div className="dropdown-options">
                {sortedOptions.map(option => (
                    <div
                        key={option}
                        className={`dropdown-option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultiSelectDropdown;
