import React from 'react';
import './ProductSection.css';
import TextField from '../../components/TextField';
import { landingHomeSections } from '../../config/sectionsData';
import useScreenSize from '../../hooks/useScreenSize';
import { SECTION_TITLE_FONT_SIZE_MOBILE, SECTION_TITLE_FONT_SIZE_DESKTOP, SECTION_DESCRIPTION_FONT_SIZE_DESKTOP, SECTION_DESCRIPTION_FONT_SIZE_MOBILE, PRODUCT_SECTION_ITEM_DESCRIPTION_MOBILE, PRODUCT_SECTION_ITEM_TITLE_MOBILE, PRODUCT_SECTION_ITEM_DESCRIPTION_DESKTOP, PRODUCT_SECTION_ITEM_TITLE_DESKTOP } from '../../config/constants';

const ProductSection: React.FC = () => {
    const { header, items } = landingHomeSections.productSection;
    const isMobile = useScreenSize();

    return (
        <div className="product-section" id="product">
            <div className="product-header">
                <TextField
                    text={header.title}
                    size={isMobile ? SECTION_TITLE_FONT_SIZE_MOBILE : SECTION_TITLE_FONT_SIZE_DESKTOP}
                    weight="bold"
                    className="header-title"
                />
                <TextField
                    text={header.description}
                    size={isMobile ? SECTION_DESCRIPTION_FONT_SIZE_MOBILE : SECTION_DESCRIPTION_FONT_SIZE_DESKTOP}
                    style={{ color: '#909090', marginBottom: '20px' }}
                    className="header-description"
                />
            </div>
            <div className="product-grid">
                {items.map((item, index) => (
                    <div key={index} className={`product-item ${index % 2 !== 0 ? 'reverse' : ''}`}>
                        <img src={item.imageUrl} alt={item.title} className="product-image" />
                        <div className="product-text">
                            <TextField
                                text={item.title}
                                size={isMobile ? PRODUCT_SECTION_ITEM_TITLE_MOBILE : PRODUCT_SECTION_ITEM_TITLE_DESKTOP}
                                weight="bold"
                                className="product-item-title"
                                style={{ color: '#e9002f' }}
                            />
                            <TextField
                                text={item.description}
                                size={isMobile ? PRODUCT_SECTION_ITEM_DESCRIPTION_MOBILE : PRODUCT_SECTION_ITEM_DESCRIPTION_DESKTOP}
                                className="product-item-description"
                                style={{ color: '#ffff' }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductSection;
