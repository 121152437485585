import React, { useEffect, useState } from 'react';
import './MSTabs.css';

type TabsProps = {
    options: string[];
    selectedOption: string;
    onSelect: (option: string) => void;
    className?: string;
    style?: React.CSSProperties;
};

const MSTabs: React.FC<TabsProps> = ({ options, selectedOption, onSelect, className = '', style = {} }) => {
    const [activeTab, setActiveTab] = useState(selectedOption);

    useEffect(() => {
        // This will set the show-arrow class after the component mounts
        if (activeTab) {
            setTimeout(() => {
                const activeElement = document.querySelector('.tab.active');
                if (activeElement) {
                    activeElement.classList.add('show-arrow');
                }
            }, 100);
        }
    }, [activeTab]);

    useEffect(() => {
        // Clean up the class when the component unmounts or active tab changes
        return () => {
            const activeElement = document.querySelector('.tab.active');
            if (activeElement) {
                activeElement.classList.remove('show-arrow');
            }
        };
    }, [activeTab]);

    const handleTabClick = (option: string) => {
        setActiveTab(option);
        onSelect(option);
    };

    return (
        <div className={`tabs ${className}`} style={style}>
            {options.map((option) => (
                <div
                    key={option}
                    className={`tab ${activeTab === option ? 'active' : ''}`}
                    onClick={() => handleTabClick(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default MSTabs;
