import React from 'react';
import './MSAlert.css';
import { FaInfoCircle } from 'react-icons/fa';

type AlertProps = {
    message: string;
};

const MSAlert: React.FC<AlertProps> = ({ message }) => {
    return (
        <div className="alert">
            <FaInfoCircle className="alert-icon" />
            <span className="alert-message">{message}</span>
        </div>
    );
};

export default MSAlert;
