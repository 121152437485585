import React from 'react';
import MSButton from '../core-components/MSButton';

type CTAButtonProps = {
    text: string;
    variant?: 'text' | 'contained' | 'outlined';
    theme?: 'primary' | 'secondary';
    icon?: React.ReactNode;
    onClick?: () => void;
    className?: string;  
};

const CTAButton: React.FC<CTAButtonProps> = ({
    text,
    variant = 'contained',
    theme = 'primary',
    icon,
    onClick,
    className,  
}) => {
    return <MSButton text={text} variant={variant} theme={theme} icon={icon} onClick={onClick} className={className} />;
};

export default CTAButton;
