import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Header.css';
import Logo from '../core-components/MSLogo';
import TextField from './TextField';
import MSButton from '../core-components/MSButton';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let headerClasses = 'header';
    if (scrolled) {
        headerClasses += ' scrolled';
    }

    return (
        <header className={headerClasses}>
            <div className="header-container">
                <div className="header-left-group">
                    <div className="ms-logo" onClick={handleLogoClick}>
                        <Logo />
                    </div>
                </div>
                {!isMobile && (
                    <>
                        <div className="header-middle-group">
                            <nav className="nav-center">
                                <ul>
                                    <li>
                                        <ScrollLink to="product-section" smooth={true} duration={500}>
                                            <TextField text="Product" weight='bold' size={16} />
                                        </ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="about-section" smooth={true} duration={500}>
                                            <TextField text="About" weight='bold' size={16} />
                                        </ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="features-section" smooth={true} duration={500}>
                                            <TextField text="Features" weight='bold' size={16} />
                                        </ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="contact-section" smooth={true} duration={500}>
                                            <TextField text="Contact" weight='bold' size={16} />
                                        </ScrollLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right-group">
                            <ScrollLink to="contact-section" smooth={true} duration={500}>
                                <MSButton
                                    text="Join the Revolution"
                                    variant='contained'
                                    theme='primary'
                                />
                            </ScrollLink>
                        </div>
                    </>
                )}
                {isMobile && (
                    <div className="hamburger-menu">
                        {menuOpen ? (
                            <FaTimes className="hamburger-icon" onClick={toggleMenu} />
                        ) : (
                            <FaBars className="hamburger-icon" onClick={toggleMenu} />
                        )}
                        {menuOpen && (
                            <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
                                <ScrollLink className="menu-item" to="product-section" smooth={true} duration={500} onClick={toggleMenu}>
                                    Product
                                </ScrollLink>
                                <ScrollLink className="menu-item" to="about-section" smooth={true} duration={500} onClick={toggleMenu}>
                                    About
                                </ScrollLink>
                                <ScrollLink className="menu-item" to="features-section" smooth={true} duration={500} onClick={toggleMenu}>
                                    Features
                                </ScrollLink>
                                <ScrollLink className="menu-item" to="contact-section" smooth={true} duration={500} onClick={toggleMenu}>
                                    Contact
                                </ScrollLink>
                                <ScrollLink className="menu-item" to="contact-section" smooth={true} duration={500} onClick={toggleMenu}>
                                    Join the Revolution
                                </ScrollLink>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
