import React from 'react';
import './TextField.css';

type TextFieldProps = {
    text: string;
    type?: 'regular' | 'spaced' | 'condensed';
    size?: number;
    weight?: 'thin' | 'regular' | 'bold';
    style?: React.CSSProperties;
    gradient?: boolean;
    className?: string;
};

const TextField: React.FC<TextFieldProps> = ({
    text, type = 'regular', size = 14, weight = 'regular', style, gradient, className
}) => {
    const gradientStyle = gradient
        ? {
            background: 'linear-gradient(to right, #e9002f, #8e44ad)',
            WebkitBackgroundClip: 'text',
            color: 'transparent'
        }
        : {};

    return (
        <div className={`ms-text ${type} ${weight} ${className}`} style={{ fontSize: size, ...style, ...gradientStyle }}>
            {text}
        </div>
    );
};

export default TextField;
