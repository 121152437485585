import React, { useState } from 'react';
import MultiSelectDropdown from './MultiSelectDropdown';

const TestComponent: React.FC = () => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const options = [
        "Spotify", "Youtube", "Amazon Music", "Apple Music", "Soundcloud", "Tidal", "Others"
    ];

    return (
        <div style={{ padding: '20px' }}>
            <h1>MultiSelectDropdown Test</h1>
            <MultiSelectDropdown
                options={options}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                className="custom-dropdown"
                style={{ width: '300px' }}
            />
            <div style={{ marginTop: '20px' }}>
                <strong>Selected Options:</strong>
                <ul>
                    {selectedOptions.map(option => (
                        <li key={option}>{option}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TestComponent;
