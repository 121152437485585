import React from 'react';
import './TeamSection.css';
import { landingHomeSections } from '../../config/sectionsData';
import TextField from '../../components/TextField';

const TeamSection: React.FC = () => {
    const { header, teamMembers } = landingHomeSections.teamSection;

    return (
        <div className="team-section">
            <div className="team-header">
                <TextField text={header.title} size={40} weight="bold" />
                <TextField
                    text={header.description}
                    size={20}
                    style={{ color: '#909090', marginBottom: '20px' }}
                />
            </div>
            <div className="team-grid">
                {teamMembers.map((member) => (
                    <div className="team-item" key={member.id}>
                        <div className="team-card">
                            <div className="team-card-front">
                                <img src={member.imageUrl} alt={member.name} className="team-image" />
                                <div className="team-role">
                                    <strong>{member.name}</strong>
                                    <p>{member.role}</p>
                                </div>
                            </div>
                            <div className="team-card-back">
                                <p>{member.details}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamSection;
