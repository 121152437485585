import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IntroSection.css';
import TextField from '../../components/TextField';
import AnnouncementBar from '../../components/AnnouncementBar';
import CTAButton from '../../components/CTAButton';
import { landingHomeSections } from '../../config/sectionsData';
import { FaPlayCircle } from 'react-icons/fa';
import MSButton from '../../core-components/MSButton';
import useScreenSize from '../../hooks/useScreenSize';
import Modal from '../../components/Modal'; // Ensure correct path to Modal component
import {
    MAIN_INTRO_SECTION_DESCRIPTION_SIZE_DESKTOP,
    MAIN_INTRO_SECTION_DESCRIPTION_SIZE_MOBILE,
    MAIN_INTRO_SECTION_TEXT_SIZE_DESKTOP,
    MAIN_INTRO_SECTION_TEXT_SIZE_MOBILE,
    MAIN_INTRO_SECTION_TITLE_SIZE_DESKTOP,
    MAIN_INTRO_SECTION_TITLE_SIZE_MOBILE
} from '../../config/constants';

const IntroSection: React.FC = () => {
    const { title, description } = landingHomeSections.introSection.header;
    const navigate = useNavigate();
    const isMobile = useScreenSize();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEarlyAccessClick = () => {
        navigate('/early-access');
    };

    const handleWatchDemoClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="ms-landing-home-intro-container">
            <AnnouncementBar />
            <div className="intro-title">
                <TextField
                    text={title.main}
                    size={isMobile ? MAIN_INTRO_SECTION_TITLE_SIZE_MOBILE : MAIN_INTRO_SECTION_TITLE_SIZE_DESKTOP}
                    weight='bold'
                    style={{ color: '#e9002f' }}
                />
                <TextField
                    text={title.sub}
                    size={isMobile ? MAIN_INTRO_SECTION_TITLE_SIZE_MOBILE : MAIN_INTRO_SECTION_TITLE_SIZE_DESKTOP}
                    weight="bold"
                />
            </div>
            <div className="intro-description">
                <TextField
                    text={description}
                    size={isMobile ? MAIN_INTRO_SECTION_DESCRIPTION_SIZE_MOBILE : MAIN_INTRO_SECTION_DESCRIPTION_SIZE_DESKTOP}
                    style={{ color: '#909090', marginBottom: '20px' }}
                />
            </div>
            <div className="cta-watch-demo">
                <CTAButton
                    text="Watch Demo"
                    variant="outlined"
                    theme="secondary"
                    icon={<FaPlayCircle />}
                    onClick={handleWatchDemoClick}
                />
            </div>
            <div className="cta-container">
                <TextField
                    text="If you would like to become one of our early users and avail first comer benefits, you can join now or become an alpha partner."
                    size={isMobile ? MAIN_INTRO_SECTION_TEXT_SIZE_MOBILE : MAIN_INTRO_SECTION_TEXT_SIZE_DESKTOP}
                    style={{ color: '#ffffff', marginBottom: '20px', textAlign: 'center', maxWidth: '900px' }}
                />
                <MSButton
                    text="Sign-up for early access"
                    variant="contained"
                    theme="secondary"
                    style={{ borderRadius: "25px" }}
                    onClick={handleEarlyAccessClick}
                />
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default IntroSection;
