import React, { useState } from 'react';
import './EarlyAccessPage.css';
import { FaCircle } from 'react-icons/fa';
import TextField from '../components/TextField';
import MSTabs from '../core-components/MSTabs';
import useScreenSize from '../hooks/useScreenSize';
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import axios from 'axios';

const labelStyles = {
    color: "#030404"
};

const EarlyAccessPage: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState('Artists');
    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
    const [selectedRights, setSelectedRights] = useState<string[]>([]);
    const [selectedProductOfferings, setSelectedProductOfferings] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        city: '',
        state: '',
        country: '',
        role: '',
        website: '',
        genre: '',
        releases: '',
        betaPartnerInterest: ''
    });
    const isMobile = useScreenSize();

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const dataToSend = {
            ...formData,
            managedRights: selectedRights.length ? selectedRights : undefined,
            interestedProductOfferings: selectedProductOfferings,
            preferredDistributionPlatforms: selectedPlatforms.length ? selectedPlatforms : undefined
        };
        try {
            await axios.post('https://us-central1-mixstream-platform.cloudfunctions.net/submitSignUpForm', dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            alert('Form submitted successfully.');
            // Clear form data after successful submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                city: '',
                state: '',
                country: '',
                role: '',
                website: '',
                genre: '',
                releases: '',
                betaPartnerInterest: ''
            });
            setSelectedPlatforms([]);
            setSelectedRights([]);
            setSelectedProductOfferings([]);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form.');
        }
    };

    const genres = [
        "Rock", "Pop", "Hip-Hop", "Jazz", "Classical", "Electronic", "Country",
        "Reggae", "Blues", "R&B", "Soul", "Metal", "Folk", "Punk", "Disco",
        "Funk", "Latin", "Reggaeton", "EDM", "Alternative", "Others"
    ];

    const artistRoles = [
        "Independent Artist", "Signed Artist", "Solo Artist", "Band Member",
        "Session Musician", "Producer/Composer", "Songwriter", "DJ/EDM Artist",
        "Streaming Platform Representative", "Cover Artist", "Vocalist", "Instrumentalist", "Multimedia Artist", "Others"
    ];

    const partnerRoles = [
        "Record Label", "Music Producer", "Publisher", "Songwriter",
        "Music Distributor", "DJ", "Session Musician", "Music Licensing Agent",
        "Music Promoter", "Music Educator", "Composer", "Independent Music Professional",
        "Advertising Agency", "Others"
    ];

    const rightsTypes = [
        "Master", "Publishing", "Performance", "Mechanical", "Synchronization",
        "Digital Distribution", "Public Performance", "Reproduction", "Licensing",
        "Others"
    ]

    const primaryStreamingPlatformUsed = [
        "Spotify", "Youtube", "Amazon Music", "Apple Music", "Soundcloud", "Tidal", "Others"
    ];

    const mixstreamPlatformOfferings = [
        "Centralized Digital Rights Management", "Royalties Distribution & Management",
        "Real-Time Usage Analytics Dashboards", "Metadata Management",
        "Automated Agreements & Licensing", "Content Distribution Integration"
    ]

    const roles = selectedOption === 'Partners' ? partnerRoles : artistRoles;

    return (
        <div className="early-access-page">
            <div className="early-access-header">
                <TextField
                    text="Join the Mixstream Ecosystem"
                    size={isMobile ? 42 : 70}
                    weight="bold"
                />
                <TextField
                    text="Be among the first to experience Mixstream. Sign up for early access and enjoy exclusive benefits!"
                    size={isMobile ? 16 : 20}
                    style={{ color: '#909090', marginBottom: '20px' }}
                />
            </div>
            <div className="early-access-content">
                <div className="early-access-left">
                    <MSTabs
                        options={['Artists', 'Partners']}
                        selectedOption={selectedOption}
                        onSelect={handleOptionClick}
                    />
                    {selectedOption === 'Artists' ? (
                        <ul className="access-benefits">
                            <li><span className="benefit-icon"><FaCircle /></span>Receive updates and participate in the beta phase</li>
                            <li><span className="benefit-icon"><FaCircle /></span>Contribute to the platform's development</li>
                            <li><span className="benefit-icon"><FaCircle /></span>Stay updated about Mixstream's grant programs</li>
                            <li><span className="benefit-icon"><FaCircle /></span>Enjoy exclusive discounts and offers</li>
                        </ul>
                    ) : (
                        <div className="access-benefits">
                            <p>If you're working in the music industry and would like to explore partnership opportunities, fill out the form and we'll be in touch soon.</p>
                        </div>
                    )}
                </div>
                <div className="early-access-right">
                    <div className="early-access-form">
                        <TextField
                            text="Leave a request and we'll get back to you soon"
                            size={isMobile ? 14 : 16}
                            style={{
                                maxWidth: "400px",
                                marginBottom: "30px",
                                color: "#030404"
                            }}
                        />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Name" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <input type="text" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                                </div>
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Email" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <input type="email" id="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                                </div>
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Phone" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                    </div>
                                    <input type="tel" id="phone" name="phone" placeholder="Phone" value={formData.phone} onChange={handleInputChange} />
                                </div>
                                {selectedOption === 'Partners' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Company" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <input type="text" id="company" name="company" placeholder="Company/Organization" value={formData.company} onChange={handleInputChange} />
                                    </div>
                                ) : null}
                                <div className="address-inputs">
                                    <div>
                                        <div className="input-label">
                                            <TextField text="City" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <input type="text" id="city" name="city" placeholder="City" value={formData.city} onChange={handleInputChange} />
                                    </div>
                                    <div>
                                        <div className="input-label">
                                            <TextField text="State" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <input type="text" id="state" name="state" placeholder="State" value={formData.state} onChange={handleInputChange} />
                                    </div>
                                    <div>
                                        <div className="input-label">
                                            <TextField text="Country" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <input type="text" id="country" name="country" placeholder="Country" value={formData.country} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                {selectedOption === 'Artists' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Website/Portfolio" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <input type="text" id="website" name="website" placeholder="Website/Portfolio" value={formData.website} onChange={handleInputChange} />
                                    </div>
                                ) : null}
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Role" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <select id="role" name="role" value={formData.role} onChange={handleInputChange} required>
                                        <option value="" disabled>Select Type</option>
                                        {roles.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>
                                {selectedOption === 'Artists' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Genre" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <select id="genre" name="genre" value={formData.genre} onChange={handleInputChange} required>
                                            <option value="" disabled>Select Genre</option>
                                            {genres.map((genre) => (
                                                <option key={genre} value={genre}>{genre}</option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                                {selectedOption === 'Artists' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Number of Releases" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <input type="number" name="releases" placeholder="Number of Releases" value={formData.releases} onChange={handleInputChange} />
                                    </div>
                                ) : null}
                                {selectedOption === 'Partners' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Types of Rights Managed" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <MultiSelectDropdown
                                            options={rightsTypes}
                                            selectedOptions={selectedRights}
                                            setSelectedOptions={setSelectedRights}
                                            className='multi-select'
                                        />
                                    </div>
                                ) : null}
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Mixstream's Product Offerings you are interested in" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                    </div>
                                    <MultiSelectDropdown
                                        options={mixstreamPlatformOfferings}
                                        selectedOptions={selectedProductOfferings}
                                        setSelectedOptions={setSelectedProductOfferings}
                                        className='multi-select'
                                    />
                                </div>
                                {selectedOption === 'Artists' ? (
                                    <div className="input-group">
                                        <div className="input-label">
                                            <TextField text="Preferred Distribution Platforms" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        </div>
                                        <MultiSelectDropdown
                                            options={primaryStreamingPlatformUsed}
                                            selectedOptions={selectedPlatforms}
                                            setSelectedOptions={setSelectedPlatforms}
                                            className='multi-select'
                                        />
                                    </div>
                                ) : null}
                                <div className="input-group">
                                    <div className="input-label">
                                        <TextField text="Are you interested in becoming our beta partner?" size={isMobile ? 12 : 14} weight="bold" style={labelStyles} />
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <select name="betaPartnerInterest" value={formData.betaPartnerInterest} onChange={handleInputChange} required>
                                        <option value="" disabled>Select...</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <button type="submit" className="submit-button">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EarlyAccessPage;
