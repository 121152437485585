import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout';
import LandingHome from './pages/LandingHome';
import EarlyAccessPage from './pages/EarlyAccessPage';
import TestComponent from './components/TestComponent';

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Router>
      <BaseLayout>
        <Routes>
          <Route path="/" element={<LandingHome setIsLoaded={setIsLoaded} />} />
          <Route path="/early-access" element={<EarlyAccessPage />} />
          <Route path="/test" element={<TestComponent />} />
        </Routes>
      </BaseLayout>
    </Router>
  );
};

export default App;
