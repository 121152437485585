import React from 'react';
import './Modal.css';
import TextField from './TextField';
import useScreenSize from '../hooks/useScreenSize';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const isMobile = useScreenSize();
    if (!isOpen) return null;
    
    return (
        <div className={`modal-overlay ${isOpen ? 'show' : ''}`} onClick={onClose}>
            <div className={`modal-content ${isOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <div className="modal-body">
                    <TextField
                        text="Coming Soon"
                        size={ isMobile ? 18 : 24}
                        weight="bold"
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField
                        text="The trailer is currently being prepared. Stay tuned!"
                        size={isMobile ? 12 : 16}
                    />
                </div>
            </div>
        </div>
    );
};

export default Modal;
