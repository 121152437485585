import React from 'react';
import './PricingSection.css';
import TextField from '../../components/TextField';
import { landingHomeSections } from '../../config/sectionsData';
import MSButton from '../../core-components/MSButton';
import useScreenSize from '../../hooks/useScreenSize';

const PricingSection: React.FC = () => {
    const { header, plans } = landingHomeSections.pricingSection;
    const isMobile = useScreenSize();

    return (
        <div className="pricing-section">
            <div className="pricing-header">
                <TextField
                    text={header.title}
                    size={isMobile ? 30 : 40}
                    weight="bold"
                    className="header-title"
                />
                <TextField
                    text={header.description}
                    size={isMobile ? 16 : 20}
                    style={{ color: '#909090', marginBottom: '40px' }}
                    className="header-description"
                />
            </div>
            <div className="pricing-grid">
                {plans.map((plan, index) => (
                    <div key={index} className={`pricing-card ${plan.isHighlighted ? 'highlighted' : ''}`}>
                        <div className="card-header">
                            <TextField
                                text={plan.title}
                                size={isMobile ? 30 : 40}
                                weight="bold"
                                style={{ color: '#e9002f' }}
                            />
                            <TextField
                                text={plan.price}
                                size={isMobile ? 24 : 30}
                            />
                        </div>
                        <ul className="plan-features">
                            {plan.features.map((feature, idx) => (
                                <li key={idx}>{feature}</li>
                            ))}
                        </ul>
                        <MSButton text={plan.button.text} theme='primary' />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PricingSection;
