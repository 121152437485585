import React, { useState, useEffect } from 'react';
import './LandingHome.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import landingHomeSections from '../sections/LandingHome/ordering';

interface Section {
  id: string;
  section: JSX.Element;
}

const LandingHome: React.FC<{ setIsLoaded: (loaded: boolean) => void }> = ({ setIsLoaded }) => {
  const [items, setItems] = useState<Section[]>([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(landingHomeSections.slice(0, 1));
  }, []);

  useEffect(() => {
    if (items.length === landingHomeSections.length) {
      setIsLoaded(true);
    }
  }, [items, setIsLoaded]);

  const fetchMoreData = () => {
    if (items.length >= landingHomeSections.length) {
      setHasMore(false);
      return;
    }

    const nextItems = landingHomeSections.slice(items.length, items.length + 1);
    setItems([...items, ...nextItems]);
  };

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
    >
      {items.map((item, index) => (
        <div key={index} id={item.id} className="scroll-section">
          {item.section}
        </div>
      ))}
    </InfiniteScroll>
  );
};

export default LandingHome;