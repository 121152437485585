import React from 'react';
import IntroSection from './IntroSection';
import FeaturesSection from './FeaturesSection';
import TeamSection from './TeamSection';
import PricingSection from './PricingSection';
import StayUpdatedSection from './StayUpdatedSection';
import ProductSection from './ProductSection';

const landingHomeSections = [
    { id: 'intro-section', section: <IntroSection /> },
    { id: 'product-section', section: <ProductSection /> },
    { id: 'features-section', section: <FeaturesSection /> },
    { id: 'team-section', section: <TeamSection /> },
    { id: 'pricing-section', section: <PricingSection /> },
    { id: 'contact-section', section: <StayUpdatedSection /> },
];

export default landingHomeSections;
