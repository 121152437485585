import React, { useState } from 'react';
import './MSButton.css';

type MSButtonProps = {
    text: string;
    variant?: 'text' | 'contained' | 'outlined';
    theme?: 'primary' | 'secondary';
    icon?: React.ReactNode;
    onClick?: () => void;
    onFocus?: 'text' | 'contained' | 'outlined';
    className?: string;
    style?: React.CSSProperties;
};

const MSButton: React.FC<MSButtonProps> = ({
    text,
    variant = 'contained',
    theme = 'primary',
    icon,
    onClick,
    onFocus,
    className = '',
    style,
}) => {
    const [currentVariant, setCurrentVariant] = useState(variant);

    const handleClick = () => {
        if (onFocus) {
            setCurrentVariant(onFocus);
        }
        if (onClick) {
            onClick();
        }
    };

    let buttonClassName = 'button';
    buttonClassName += ` ${currentVariant}`;
    buttonClassName += ` ${theme}`;
    buttonClassName += ` ${className}`;

    return (
        <button className={buttonClassName} onClick={handleClick} style={style}>
            {icon && <span className="button-icon">{icon}</span>}
            <span>{text}</span>
        </button>
    );
};

export default MSButton;
